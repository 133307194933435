<template>
  <div class="selectSchool">
    <div class="text">
      <span>欢迎</span>
      <span>请输入以下信息</span>
    </div>
    <div class="wid90" style="margin: 15vh auto;">
      <van-field
          class="br10"
          clearable
          :error="valueError"
          label="企业编号"
          v-model="value"
          placeholder="请输入企业编号"
      />
    </div>
    <div class="wid90" style="margin: 10px auto">
      <van-button type="primary" class="br10" style="width: 100%" @click="btnOk(1)">注册申请</van-button>
<!--      <van-button type="warning" class="br10" style="width: 100%;margin: 16px auto" @click="btnOk(2)">修改申请</van-button>
      <van-button type="info" class="br10" style="width: 100%" @click="btnOk(3)">申请列表</van-button>-->
    </div>
<!--    <div class="wid90" style="margin: 10px auto">
&lt;!&ndash;      <van-button type="primary" class="br10" style="width: 100%" @click="btnOk(1)">注册申请</van-button>&ndash;&gt;
      <van-button type="primary" class="br10" style="width: 100%" @click="faceCamera()">拍照</van-button>
    </div>-->
  </div>
</template>

<script>
import {getCenter} from "@/api/register/register";

export default {
  name: "SelectEnter",
  //组件引入
  components: {},
  //父子组件传值
  props: {},
  //计算属性，可以缓存，避免每次计算都要重新计算
  computed: {},
  //filters 过滤器
  filters: {},
  //数据
  data() {
    return {
      valueError: false,//显示红色输入框
      value: ''
    }
  },
  //在模板渲染成html前调用,即通常初始化某些属性值,然后再渲染成视图。
  created() {
  },
  //在模板渲染成html后调用，通常是初始化页面完成后，再对html的dom节点进行一些需要的操作。
  mounted() {
  },
  //方法 处理逻辑
  methods: {
    btnOk(type) {
      if (!this.value) {
        let message = type === 1 ? '请先输入企业编号，再进行注册登记。' : type === 2 ? '请先输入企业编号，再进行修改注册信息。' : type === 3 ? '请先输入企业编号，再进行查询已申请列表。' : '我都不知道你做了什么操作!'
        this.valueError = true
        this.$notify({type: 'danger', message: message});
        return
      }
      this.$toast.loading({duration: 0, message: '获取企业信息中...'})
      //获取企业信息
      getCenter({centerId: this.value}).then(res => {
        if (res.code === 0 && res.data) {
          this.$toast.clear()
          this.$store.commit('SET_ENTER_ID', this.value)//将输入的企业编号存入store
          this.$store.commit('SET_CENTER_DATA', res.data)//将输入的企业信息存入store
          if (type === 1) {
            //注册
            this.$router.push({path: '/register'})
          }
          if (type === 2) {
            this.$toast.fail('还未开放此功能')
          }
          if (type === 3) {
            this.$router.push({path: '/RegisterList'})
          }
        } else {
          this.$toast.fail('未找到相关企业、请检查！')
        }
      }).catch(err => {
        console.log(err, 'err');
        this.$toast.fail('获取失败,请稍后重试！')
      })
    },
    faceCamera(){
      this.$router.push({path:'/faceCamera'})
    }
  }
}
</script>

<style scoped lang="scss">
.selectSchool {
  width: 100%;
  height: 100vh;
  background: #F7C8B8;
  position: relative;

  .text {
    display: flex;
    flex-direction: column;
    padding: 10vh 0 0 30px;
    font-size: 20px;
    font-weight: bold;
  }

  .text span {
    padding: 3px;
  }

  .bg_ch {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 60%;
    height: 30vh;
    background: url("~@/assets/image/visitor/bg-ch.svg") no-repeat center center;
    background-size: contain;
  }

  .wid90 {
    width: 90%;
  }

  .br10 {
    border-radius: 10px;
  }
}
</style>
